    jQuery(document).ready(function($) {
	new WOW().init();

    $('body').addClass('loading');
    setTimeout(function(){
        $('body').addClass('loaded');
    }, 2000);

	/* ====================== Mobile Menu Trigger ======================  */

	$('.nav-trigger').click(function(){
		$(this).toggleClass('open');
		$('body, html').toggleClass('nav-open');
		$('.header-nav').toggleClass('open');
		
	});

	$('.nav-trigger--close').click(function(){
		if($(window).scrollTop() > 0) {
			$('.header').addClass('active');
		}
	});
		
    $('.demo-categories ul li a').click(function(){
        var playerOffset = $('.audio-demos__player').offset().top - 100;
        $("html, body").animate({ scrollTop: playerOffset });
    });

    $(".ui-btn[href^='#']").click(function(){
        var target = $(this.hash);
        $('html,body').animate({
            scrollTop: target.offset().top
          }, 600);
          return false;
    });

    $('.faq__row').click(function(){
        $('.a').not($(this).find('.a')).slideUp();
        $(this).find('.a').slideToggle();
    });

    $('.scroll-top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 1000);
    });

    $('.header-nav #menu-menu li.menu-item-has-children > a').click(function(){
        $('.header-nav #menu-menu .sub-menu').not($(this).next('.sub-menu')).slideUp();
        $(this).next('.sub-menu').slideToggle();
    });

	$(window).scroll(function() {
        var windowScroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        var translate3d = windowScroll / 5;

		if (windowScroll > 0) {
			$('.header').addClass('active');
		} else {
			$('.header').removeClass('active');
		}

        if(windowScroll < windowHeight) {
            $(".our-story__bg").css('transform', 'translate3d(0px,'+ translate3d +'px, 0px)');
        }

        if($('body').hasClass('page-template-demos')) {

            if (windowScroll > $('.header').outerHeight()) {
                $('.demo-categories').addClass('fixed');
                $('.demo-categories-push').addClass('fixed');
            } else {
                $('.demo-categories').removeClass('fixed');
                $('.demo-categories-push').removeClass('fixed');
            }
        }
	});

    /* ====================== GSAP Animations ======================  */
    if($('body').hasClass('page-template-demos')) {
        var controller = new ScrollMagic.Controller();

        var abc123 = TweenMax.fromTo($('.player-icon--1'), 1, {y: -100}, {y:400});

        var scene = new ScrollMagic.Scene({
            triggerElement: $('.video-demos__player'),
            duration: 4000
        })
        .setTween(abc123)
        .addTo(controller);

        var abc321 = TweenMax.fromTo($('.player-icon--2'), 1, {y: -100}, {y:400});

        var scene2 = new ScrollMagic.Scene({
            triggerElement: $('.video-demos__player'),
            duration: 3000
        })
        .setTween(abc321)
        .addTo(controller);

        $('.wave').each(function(){
            var scene3 = new ScrollMagic.Scene({
                triggerElement: $('.audition-block'),
            })
            .setClassToggle(this, 'fade-in')
            .addTo(controller);
        });
    }


	/* ====================== Owl Sliders ======================  */
	$('.brands__slider').owlCarousel({
        items: 1,
        loop: true,
        nav: false,
        dots: true,
        animateIn: 'fadeIn', // add this
        animateOut: 'fadeOut', // and this
        autoplay: true,
        autoplayTimeout: 5000,
        smartSpeed: 800,
        autoplaySpeed: 800,
    });

    function brandSlider() {
        if($(window).width() > 460) {
            $('.brands__logo-slider').addClass('owl-carousel');

            $('.brands__logo-slider').owlCarousel({
                items: 2,
                loop: true,
                nav: false,
                dots: false,
                margin: 30,
                autoplay: true,
                autoplayTimeout: 2000,
                smartSpeed: 800,
                autoplaySpeed: 800,
                responsive:{
                    0:{
                        items:5
                    },
                    768:{
                        items:6
                    },
                    992:{
                        items:7
                    },
                    1200:{
                        items:5,           
                    }
                }
            });
        } else {
            $('.brands__logo-slider').owlCarousel('destroy');
            $('.brands__logo-slider').removeClass('owl-carousel');
        }
    };

    brandSlider();

    $( window ).resize(function() {
        brandSlider();
    });


    // Vimeo Sliders

    $(document).ready(function() {

  var sync1 = $("#sync1");
  var sync2 = $("#sync2");
  var slidesPerPage = 5; //globaly define number of elements per page
  var syncedSecondary = true;

  sync1.owlCarousel({
    items : 1,
    slideSpeed : 2000,
    nav: true,
    autoplay: false,
    dots: false,
    loop: true,
    responsiveRefreshRate : 200,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>','<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #000;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
  }).on('changed.owl.carousel', syncPosition);

  sync2
    .on('initialized.owl.carousel', function () {
      sync2.find(".owl-item").eq(0).addClass("current");
    })
    .owlCarousel({
    items : slidesPerPage,
    dots: false,
    nav: false,
    smartSpeed: 200,
    slideSpeed : 500,
    margin: 15,
    slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
    responsiveRefreshRate : 100,
    responsive:{
        0:{
            items:2
        },
        768:{
            items:3
        },
        992:{
            items:4
        },
        1200:{
            items:5,           
        }
    }
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    //var current = el.item.index;
    
    //if you disable loop you have to comment this block
    var count = el.item.count-1;
    var current = Math.round(el.item.index - (el.item.count/2) - .5);
    
    if(current < 0) {
      current = count;
    }
    if(current > count) {
      current = 0;
    }
    
    //end block

    sync2
      .find(".owl-item")
      .removeClass("current")
      .eq(current)
      .addClass("current");
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();
    
    if (current > end) {
      sync2.data('owl.carousel').to(current, 100, true);
    }
    if (current < start) {
      sync2.data('owl.carousel').to(current - onscreen, 100, true);
    }
  }
  
  function syncPosition2(el) {
    if(syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }
  
  sync2.on("click", ".owl-item", function(e){
    e.preventDefault();
    var number = $(this).index();
    sync1.data('owl.carousel').to(number, 300, true);
  });
});
}); 
